import config from '@/methods/config'
import table from './table'
export default {
	form_data_seo: {
		type: Object,
		default: () => {
			return {
				list: []
			}
		}
	},
	submit_preprocessing: {
		type: Object,
		default: () => {
			return {
	
			}
		}
	},
	...table,
	rowSelection: {
		type: [Object],
		default: null,
	},
	expandRowByClick: {
		type: Boolean,
		default: false,
	},
	expandedRowRender: {
		type: Boolean,
		default: false,
	},
	slot_table_list: {
		type: Array,
		default: () => {
			return [];
		}
	},
	slot_seo_list: {
		type: Array,
		default: () => {
			return [];
		}
	},
	get_table_list: {
		type: Function,
		default: () => {
			return null;
		}
	},
	is_page: {
		type: [Boolean],
		default: true
	},

	is_created_get_list: {
		type: Boolean,
		default: true
	},

	style_mode:{
		type: String,
		default:'table_list'
	},

	scroll:{
		type:Object,
		default(){
			return {
				
			}
		}
	}
}